import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import { StaticImage } from "gatsby-plugin-image"
import ImageBlock from "../components/imageblock"
import ImageComponent from "../components/image"

const GoatsPage = () => (
  <Layout template="subpage">
    <Seo
        title="Goats at M's Mini Farm"
        description={ `At M's Mini Farm we have 5 Nygerian Dwarfs Goats. They happen to be very social and love their treats.`}/>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h1>Goats</h1>
            <p className={ `intro-statement` }>
            We are the Garys, we are goats. We are rambunctious, crazy, insanely cute and cuddly. Above all things, we love treats and attention. We came to M’s Mini Farm Summer 2021, as just wee little kids, born Spring 2021.
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-dark-slate text-center` }>
        <div className={ `container-full inner` }>
            <h2>Meet the Garys</h2>
            <p>Some photos of our escapades, afterall we are goats...</p>
            <ImageBlock>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660538277/MsMiniFarm/onyx_hk0082.jpg"
                        alt="Onyx looking cute in the grass"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660538276/MsMiniFarm/ash-look-at-me_ldxyfa.jpg"
                        alt="Look at me I'm a goat! "
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660538276/MsMiniFarm/big-gary-so-pretty-in-l-flowers_van9bn.jpg"
                        alt="Big Gary with Flowers in his Hair"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1658696066/MsMiniFarm/hereford-on-grass_hkarpo.jpg"
                        alt="Hereford the Goat"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1658695982/MsMiniFarm/big-gary-on-spool_ek8mui.jpg"
                        alt="Big Gary on a Spool"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1660538279/MsMiniFarm/just-gary-spool_b0vnc3.jpg"
                        alt="Just Gary Hanging out on a spool"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1658696102/MsMiniFarm/all-garys-on-concrete-mountain_n4suak.jpg"
                        alt="All the Goats hanging out on their concrete mountain"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="https://res.cloudinary.com/simplesaint/image/upload/v1658696044/MsMiniFarm/another-ash-on-grass_nt07f9.jpg"
                        alt="The photogenic Ash Gary the Goat, looking sharp"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/ash-hereford-hay.jpeg"
                        alt="Ash and Hereford laying in the hay"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/big-gary-snuggles.jpeg"
                        alt="Big Gary the Goat Snuggles"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/browsing-time.jpeg"
                        alt="The goats are out and about browsing"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/goats-kitty.jpeg"
                        alt="Unsuspecting Kitty and Curious Goats"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/meal-time.jpeg"
                        alt="Goats sticking their head through the slats on our pallet hay bin to for meal time"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/just-gary-kitty.jpeg"
                        alt="Just Gary and Farm Kitty"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
                <ImageComponent>
                    <StaticImage
                        src="../images/goats/just-gary-young.jpeg"
                        alt="A quite young Just Gary"
                        placeholder="blurred"
                        width={600}
                        height={600}
                    />
                </ImageComponent>
            </ImageBlock>
        </div>
    </section>

  </Layout>
)

export default GoatsPage
